import React, { Fragment } from "react";
import {
  Image,
  Text,
  View,
  Page,
  Link,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "./whitelogo.png";
import logo1 from "./logo-zoplar-png-1@2x.png";
// import logo1 from "./logo-zoplar-png-1@2x.png";
import qr from "./paymentQr.jpg";

const Frameheader = React.memo(({ quotations }) => {
  let totalAmount = 0;
  let totalAmountEGst = 0;
  const styles1 = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingBottom: 30,
      lineHeight: 1.5,
      flexDirection: "column",
    },

    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },

    titleContainer1: {
      flexDirection: "row",
      margin: 0,
      backgroundColor: "#1F262E",
      padding: 15,
    },
    titleContainer: { flexDirection: "row", padding: 15 },

    logo: { width: 90 },

    reportTitle: { fontSize: 10, textAlign: "right", color: "white" },
    prevTitle: {
      fontSize: 20,
      textAlign: "center",
      color: "red",
      fontWeight: "bold",
    },

    addressTitle: {
      fontSize: 11,
      fontWeight: "bold",
      padding: 15,
      paddingTop: 0,
      paddingBottom: 0,
      color: "black",
    },

    invoice: {
      fontWeight: "bold",
      fontSize: 12,
      color: "black",
    },

    invoiceNumber: { fontSize: 11, fontWeight: "bold", color: "black" },

    address: {
      fontWeight: 400,
      fontSize: 10,
      padding: 15,
      paddingTop: 5,
    },

    theader: {
      marginTop: 10,
      fontSize: 10,
      fontWeight: "bold",
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: "auto",
      backgroundColor: "#93B5D5",
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: "auto",
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    total: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1.5,
      borderColor: "whitesmoke",
      borderBottomWidth: 1,
    },

    annHeadingView: {
      textAlign: "center",
      padding: 15,
    },
    annHeading: {
      fontSize: 15,
      fontWeight: "bold",
      textDecoration: "underline",
    },
    annPara: {
      fontSize: 13,
      width: "80%",
      textAlign: "center",
      marginLeft: "10%",
      marginTop: "10px",
      fontWeight: "bold",
    },
    ulView: {
      padding: 20,
      paddingTop: 0,
    },

    ulLi: {
      fontSize: 11,
      padding: 2,
      textAlign: "justify",
    },
    note: {
      fontSize: 11,
      textAlign: "center",
      padding: 20,
      paddingTop: 0,
      color: "#7F7F7F",
    },

    paymentDetails: {
      display: "flex",
      flexDirection: "row",
      padding: 20,
      paddingTop: 0,
      width: "80%",
      marginLeft: "10%",
      justifyContent: "space-between",
    },

    paymentDetailsLeft: {
      padding: 10,
      width: "50%",
    },

    paymentDetailsMiddle: {
      padding: 10,
      paddingTop: 30,
    },

    paymentDetailsRight: {
      padding: 10,
      width: "42%",
    },

    accDetails: {
      fontSize: 13,
      fontWeight: "bold",
      paddingBottom: 5,
      textDecoration: "underline",
    },

    accInfo: {
      fontSize: 11,
      paddingBottom: 3,
    },

    accInfoSpan: {
      fontWeight: "bold",
    },

    orTxt: {
      fontSize: 13,
      fontWeight: "bold",
      textAlign: "center",
    },

    paymentQr: {
      width: 100,
    },

    // footer should be at the bottom of the page
    annFooter: {
      position: "absolute",
      bottom: 15,
      width: "100%",
      textAlign: "center",
      backgroundColor: "#F0F0F0",
      padding: 15,
    },

    annFooterText: {
      fontSize: 11,
      textAlign: "center",
      width: "90%",
      marginLeft: "5%",
      paddingBottom: 10,
    },

    annFooterThank: {
      fontSize: 13,
      fontWeight: "bold",
      textAlign: "center",
      width: "80%",
      marginLeft: "10%",
    },

    noteSpan: {
      fontWeight: "bold",
    },
    reportTitleMain: {
      fontSize: 13,

      fontWeight: "bold",
    },
    ref: {
      fontWeight: "bold",
    },

    headerTitle: {
      fontWeight: "bold",
    },

    totalLast: {
      fontWeight: "bold",
      fontSize: 13,
    },

    scanQr: {
      fontSize: 11,
      paddingTop: 3,
      marginLeft: 14,
    },

    footerMain: {
      position: "absolute",
      bottom: 0,
      paddingTop: 5,
      paddingBottom: 5,
      width: "100%",
      backgroundColor: "#1F262E",
    },
    footerLink: {
      fontSize: 11,
      textAlign: "center",
      color: "white",
    },
    footerPage: {
      position: "absolute",
      right: 15,
      top: 5,
      color: "white",
      fontSize: 10,
      textAlign: "right",
    },

    tableRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      padding: 15,
      paddingTop: 0,
      paddingBottom: 0,
      pageBreakInside: "avoid",
    },

    rowLeft: {
      width: "5%",
      padding: 5,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderColor: "#ADADAD",
    },

    rowRight: {
      width: "95%",
      display: "flex",
      flexDirection: "column",
    },

    rowRightAbove: {
      display: "flex",
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#ADADAD",
      // border bottom style to dotted
      borderBottomStyle: "dashed",
    },

    rowRightBelow: {
      display: "flex",
      flexDirection: "row",
    },

    rRAProductDetails: {
      width: "24%",
      borderRightWidth: 1,
      borderColor: "#ADADAD",
      padding: 5,
    },

    rRAPPUEGst: {
      width: "13%",
      borderRightWidth: 1,
      borderColor: "#ADADAD",
      padding: 5,
    },

    rRAGst: {
      width: "6%",
      borderRightWidth: 1,
      borderColor: "#ADADAD",
      padding: 5,
    },

    rRAPPUIGst: {
      width: "15%",
      borderRightWidth: 1,
      borderColor: "#ADADAD",
      padding: 5,
    },

    rRAQuantity: {
      width: "8%",
      borderRightWidth: 1,
      borderColor: "#ADADAD",
      padding: 5,
    },

    rRATotalAmount: {
      width: "18%",
      padding: 5,
      borderRightWidth: 1,
      borderColor: "#ADADAD",
    },

    rowRightBelow: {
      display: "flex",
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#ADADAD",
    },

    rRBProductDetails: {
      width: "59%",
      padding: 5,
      borderRightWidth: 1,
      borderColor: "#ADADAD",
    },

    rRBProductImage: {
      width: "41%",
      padding: 5,
      borderRightWidth: 1,
      borderColor: "#ADADAD",
    },

    rRBProductImageImg: {
      width: "160px",
      height: "160px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    headerTable: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      padding: 15,
      paddingTop: 0,
      paddingBottom: 0,
      // pageBreakInside: "avoid",
      marginTop: 10,
    },

    headerSl: {
      width: "5%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    headerProductDetails: {
      width: "24%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    headerPPUEGst: {
      width: "13%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    headerGst: {
      width: "6%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    headerPPUIGst: {
      width: "15%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    headerQuantity: {
      width: "8%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    headerTotalAmount: {
      width: "18%",
      padding: 5,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    headerSlText: {
      fontWeight: "bold",
      fontSize: 10,
      textAlign: "center",
    },

    headerProductDetailsText: {
      fontWeight: "bold",
      fontSize: 10,
      textAlign: "center",
    },

    headerProductBrand: {
      width: "16%",
      padding: 5,
      borderRightWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    headerProductBrandText: {
      fontWeight: "bold",
      fontSize: 10,
      textAlign: "center",
    },

    rRABrand: {
      width: "16%",
      padding: 5,
      borderRightWidth: 1,
      borderColor: "#ADADAD",
    },

    rRABrandText: {
      fontSize: 10,
    },

    headerPPUEGstText: {
      fontWeight: "bold",
      fontSize: 10,
      textAlign: "center",
    },

    headerGstText: {
      fontWeight: "bold",
      fontSize: 10,
      textAlign: "center",
    },

    headerPPUIGstText: {
      fontWeight: "bold",
      fontSize: 10,
      textAlign: "center",
    },

    headerQuantityText: {
      fontWeight: "bold",
      fontSize: 10,
      textAlign: "center",
    },

    headerTotalAmountText: {
      fontWeight: "bold",
      fontSize: 10,
      textAlign: "center",
    },

    leftSl: {
      fontSize: 10,
      textAlign: "center",
      fontWeight: "bold",
    },

    rRAProductDetailsText: {
      fontSize: 10,
    },

    rRAPPUEGstText: {
      fontSize: 10,
    },

    rRAGstText: {
      fontSize: 10,
    },

    rRAPPUIGstText: {
      fontSize: 10,
    },

    rRAQuantityText: {
      fontSize: 10,
    },

    rRATotalAmountText: {
      fontSize: 10,
    },

    rRBProductDetailsText: {
      fontSize: 10,
    },

    tableTotal: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: 15,
      paddingTop: 0,
      paddingBottom: 0,
      // pageBreakInside: "avoid",
    },

    tTRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },

    tTLeft: {
      width: "61%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderColor: "#ADADAD",
      backgroundColor: "#93B5D5",
    },

    tTRight: {
      width: "39%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      borderColor: "#ADADAD",
    },

    tTLeftText: {
      fontWeight: "bold",
      fontSize: 10,
    },

    tTRightText: {
      fontSize: 10,
    },

    watermark: {
      position: "absolute",
      fontSize: 120,
      top: 350,
      width: "100%",
      textAlign: "center",
      opacity: 0.08,
      transform: "rotate(-45deg)",
      zIndex: 100,
    },
    tbody2: { flex: 2, borderRightWidth: 1 },
  });

  const styles2 = StyleSheet.create({
    table: {
      display: "flex",
      flexDirection: "column",
      padding: 15,
      paddingTop: 5,
      paddingBottom: 0,
      // pageBreakInside: "avoid",
      // i want to break the page if the content is not fitting in one page,

      justifyContent: "center",
      alignItems: "center",
    },

    tableTop: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#93B5D5",
    },

    tableTopCell: {
      width: "25%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: "#ADADAD",
    },

    tableTopCellText: {
      fontSize: 10,
      fontWeight: "bold",
      textAlign: "center",
    },

    tableRow: {
      display: "flex",
      flexDirection: "row",
    },

    tableSide: {
      width: "25%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderColor: "#ADADAD",
    },

    tableSideText: {
      fontSize: 10,
      fontWeight: "bold",
      textAlign: "center",
    },

    tableNormal: {
      width: "25%",
      padding: 5,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderColor: "#ADADAD",
    },

    tableNormalText: {
      fontSize: 10,
      textAlign: "center",
    },

    paymentTermHeading: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: 15,
      paddingTop: 18,
      paddingBottom: 0,
      // pageBreakInside: "avoid",
    },

    paymentTermHeadingH1: {
      fontSize: 12,
      fontWeight: "bold",
    },

    paymentTermPara: {
      fontSize: 10,
    },

    attachmentLinks: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: 15,
      paddingTop: 18,
      paddingBottom: 0,
      // pageBreakInside: "avoid",
    },

    attachmentLinksH1: {
      fontSize: 12,
      fontWeight: "bold",
    },

    attachmentLink: {
      color: "blue",

      fontSize: 10,
    },
  });

  const attachments = quotations[0].attachmentDetails;

  const AttachementLinks = () => {
    return (
      <View style={styles2.attachmentLinks} wrap={false}>
        <View style={styles2.attachmentLinksTitle}>
          <Text style={styles2.attachmentLinksH1}>Attachments</Text>
        </View>
        {attachments.map((attachment, index) => (
          <Link
            href={attachment.attachmentLink}
            key={index}
            style={styles2.attachmentLink}
          >
            <Text
              style={{
                textDecoration: "none !important",
              }}
            >
              {"• "}
            </Text>
            {attachment.attachmentTitle}
          </Link>
        ))}
      </View>
    );
  };

  let R0 = quotations[0].paymentTerms[0].paymentTermsAmount > 0;
  let R1 = quotations[0].paymentTerms[1].paymentTermsAmount > 0;
  let R2 = quotations[0].paymentTerms[2].paymentTermsAmount > 0;
  let R3 = quotations[0].paymentTerms[3].paymentTermsAmount > 0;
  let R4 = quotations[0].paymentTerms[4].paymentTermsAmount > 0;

  const PaymentTable = () => {
    return (
      <View wrap={false}>
        <View style={styles2.paymentTermHeading}>
          <Text style={styles2.paymentTermHeadingH1}>Payment Terms</Text>

          <Text style={styles2.paymentTermPara}>
            Please find below the outlined Payment Terms for your order.
          </Text>
        </View>
        <View style={styles2.table}>
          <View style={styles2.tableTop}>
            <View style={styles2.tableTopCell}>
              <Text style={styles2.tableTopCellText}>Payment Terms</Text>
            </View>

            <View style={styles2.tableTopCell}>
              <Text style={styles2.tableTopCellText}>Amount (Rs.)</Text>
            </View>

            <View
              style={[
                styles2.tableTopCell,
                {
                  borderRightWidth: 1,
                },
              ]}
            >
              <Text style={styles2.tableTopCellText}>Percentage (%)</Text>
            </View>
          </View>

          {R0 && (
            <View style={styles2.tableRow}>
              <View style={styles2.tableSide}>
                <Text style={styles2.tableSideText}>Advance</Text>
              </View>

              <View style={styles2.tableNormal}>
                <Text style={styles2.tableNormalText}>
                  {formatIndianNumber(
                    parseFloat(quotations[0].paymentTerms[0].paymentTermsAmount)
                  )}
                </Text>
              </View>

              <View style={[{ borderRightWidth: 1 }, styles2.tableNormal]}>
                <Text style={styles2.tableNormalText}>
                  {quotations[0].paymentTerms[0].paymentTermsPercentage.toFixed(
                    2
                  )}
                  %
                </Text>
              </View>
            </View>
          )}

          {R1 && (
            <View style={styles2.tableRow}>
              <View style={styles2.tableSide}>
                <Text style={styles2.tableSideText}>On Delivery</Text>
              </View>

              <View style={styles2.tableNormal}>
                <Text style={styles2.tableNormalText}>
                  {formatIndianNumber(
                    parseFloat(quotations[0].paymentTerms[1].paymentTermsAmount)
                  )}
                </Text>
              </View>

              <View style={[{ borderRightWidth: 1 }, styles2.tableNormal]}>
                <Text style={styles2.tableNormalText}>
                  {quotations[0].paymentTerms[1].paymentTermsPercentage.toFixed(
                    2
                  )}
                  %
                </Text>
              </View>
            </View>
          )}

          {R2 && (
            <View style={styles2.tableRow}>
              <View style={styles2.tableSide}>
                <Text style={styles2.tableSideText}>On Installation</Text>
              </View>

              <View style={styles2.tableNormal}>
                <Text style={styles2.tableNormalText}>
                  {formatIndianNumber(
                    parseFloat(quotations[0].paymentTerms[2].paymentTermsAmount)
                  )}
                </Text>
              </View>

              <View style={[{ borderRightWidth: 1 }, styles2.tableNormal]}>
                <Text style={styles2.tableNormalText}>
                  {quotations[0].paymentTerms[2].paymentTermsPercentage.toFixed(
                    2
                  )}
                  %
                </Text>
              </View>
            </View>
          )}

          {R3 && (
            <View style={styles2.tableRow}>
              <View style={styles2.tableSide}>
                <Text style={styles2.tableSideText}>
                  {quotations[0].paymentTerms[3].duration} Days Credit
                </Text>
              </View>

              <View style={styles2.tableNormal}>
                <Text style={styles2.tableNormalText}>
                  {formatIndianNumber(
                    parseFloat(quotations[0].paymentTerms[3].paymentTermsAmount)
                  )}
                </Text>
              </View>

              <View style={[{ borderRightWidth: 1 }, styles2.tableNormal]}>
                <Text style={styles2.tableNormalText}>
                  {quotations[0].paymentTerms[3].paymentTermsPercentage.toFixed(
                    2
                  )}
                  %
                </Text>
              </View>
            </View>
          )}

          {R4 && (
            <View style={styles2.tableRow}>
              <View style={styles2.tableSide}>
                <Text style={styles2.tableSideText}>
                  {quotations[0].paymentTerms[4].duration} Months EMI
                </Text>
              </View>

              <View style={styles2.tableNormal}>
                <Text style={styles2.tableNormalText}>
                  {formatIndianNumber(
                    parseFloat(quotations[0].paymentTerms[4].paymentTermsAmount)
                  )}
                </Text>
              </View>

              <View style={[{ borderRightWidth: 1 }, styles2.tableNormal]}>
                <Text style={styles2.tableNormalText}>
                  {quotations[0].paymentTerms[4].paymentTermsPercentage.toFixed(
                    2
                  )}
                  %
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  };

  const InvoiceTitle = () => (
    <View style={styles1.titleContainer1} fixed>
      <View style={styles1.spaceBetween}>
        <Image style={styles1.logo} src={logo} />
        <Text style={styles1.reportTitle}>
          <Text style={styles1.reportTitleMain}>
            SAVDO TECHNOLOGIES PVT. LTD.
          </Text>
          {"\n"}(+91) 8000811160{"\n"}
          support@zoplar.com{"\n"}www.zoplar.com
        </Text>
      </View>
    </View>
  );
  const PreviewTitle = () => (
    <View fixed>
      <Text style={styles1.prevTitle}>DRAFT VERSION (FOR PREVIEW ONLY)</Text>
    </View>
  );
  function capitalizeName(name) {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const Address = () => {
    const establishmentName =
      quotations.length > 0
        ? capitalizeName(quotations[0].queryDetails.establishmentname)
        : "";

    return (
      <View style={styles1.titleContainer}>
        <View style={styles1.spaceBetween}>
          <View>
            <Text style={styles1.invoice}>
              Quotation for {establishmentName}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const UserAddress = () => (
    <View>
      <Text style={styles1.addressTitle}>Dear Sir/Ma'am,</Text>
      <Text style={[styles1.address, { paddingBottom: "0" }]}>
        We are extremely excited to help you with your procurement needs. We
        assure you of a great service and will strive for a long term
        partnership.
      </Text>
      <Text style={[styles1.address, { paddingTop: "0", paddingBottom: "0" }]}>
        We hereby enclose our quotations for the desired products. Looking
        forward to your revert for next steps!
      </Text>
    </View>
  );

  let slno = 0;

  const getSlNo = () => {
    slno++;
    return slno;
  };

  const formatIndianNumber = (num) => {
    let formattedNum;
    if (num < 20) {
      formattedNum = num.toFixed(2);
    } else if (num <= 100) {
      formattedNum = num.toFixed(1);
    } else {
      formattedNum = num.toFixed(0);
      var x = formattedNum;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return "Rs. " + res;
    }
    return "Rs. " + formattedNum;
  };

  const slashTToArray = (description) => {
    return description.split("\t");
  };

  const ItemsTable = () => (
    <View>
      <View style={styles1.headerTable} fixed>
        <View style={styles1.headerSl}>
          <Text style={styles1.headerSlText}>SN</Text>
        </View>
        <View style={styles1.headerProductDetails}>
          <Text style={styles1.headerProductDetailsText}>Product Details</Text>
        </View>

        <View style={styles1.headerProductBrand}>
          <Text style={styles1.headerProductBrandText}>Brand</Text>
        </View>
        <View style={styles1.headerPPUEGst}>
          <Text style={styles1.headerPPUEGstText}>Price Per Unit Excl GST</Text>
        </View>

        <View style={styles1.headerGst}>
          <Text style={styles1.headerGstText}>GST</Text>
        </View>
        <View style={styles1.headerPPUIGst}>
          <Text style={styles1.headerPPUIGstText}>Price Per Unit Incl GST</Text>
        </View>

        <View style={styles1.headerQuantity}>
          <Text style={styles1.headerQuantityText}>Quantity</Text>
        </View>
        <View style={styles1.headerTotalAmount}>
          <Text style={styles1.headerTotalAmountText}>Total Amount</Text>
        </View>
      </View>
      {quotations.map((quotation) =>
        quotation.itemDetails.map((item, index) => {
          // if (item.check === false) {
          //   return null;
          // }
          totalAmount += parseFloat(item.spigst) * parseFloat(item.quantity); // Update totalAmount for each item
          totalAmountEGst +=
            parseFloat(item.spegst) * parseFloat(item.quantity); // Update totalAmountEGst for each item
          return (
            <Fragment key={index}>
              {item.image[0] && item.description ? (
                <View style={styles1.tableRow} wrap={true}>
                  <View style={styles1.rowLeft}>
                    <Text style={styles1.leftSl}>{getSlNo()}</Text>
                  </View>

                  <View style={styles1.rowRight}>
                    <View style={styles1.rowRightAbove}>
                      <View style={styles1.rRAProductDetails}>
                        <Text
                          style={[
                            styles1.rRAProductDetailsText,
                            { fontWeight: "bold" },
                          ]}
                        >
                          {item.name}
                        </Text>

                        <Text
                          style={{
                            fontSize: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Specification:{" "}
                          <Text
                            style={{
                              fontSize: 10,
                              fontWeight: "normal",
                            }}
                          >
                            {(
                              item.size +
                              (item.warranty_duration !== "-"
                                ? " WITH " + item.warranty_duration
                                : "")
                            ).toUpperCase()}
                          </Text>
                        </Text>
                      </View>

                      <View style={styles1.rRABrand}>
                        <Text style={styles1.rRABrandText}>{item.brand}</Text>
                      </View>

                      <View style={styles1.rRAPPUEGst}>
                        <Text style={styles1.rRAPPUEGstText}>
                          {formatIndianNumber(parseFloat(item.spegst))}
                        </Text>
                      </View>

                      <View style={styles1.rRAGst}>
                        <Text style={styles1.rRAGstText}>{item.gst}</Text>
                      </View>

                      <View style={styles1.rRAPPUIGst}>
                        <Text style={styles1.rRAPPUIGstText}>
                          {formatIndianNumber(parseFloat(item.spigst))}
                        </Text>
                      </View>

                      <View style={styles1.rRAQuantity}>
                        <Text style={styles1.rRAQuantityText}>
                          {item.quantity}
                        </Text>
                      </View>

                      <View style={styles1.rRATotalAmount}>
                        <Text style={styles1.rRATotalAmountText}>
                          {formatIndianNumber(
                            parseFloat(item.spigst * item.quantity)
                          )}
                        </Text>
                      </View>
                    </View>

                    {item.image[0] && item.description && (
                      <View style={styles1.rowRightBelow}>
                        <View style={styles1.rRBProductDetails}>
                          <Text style={styles1.rRBProductDetailsText}>
                            {slashTToArray(item.description).map(
                              (desc, index) => (
                                <Text key={index}>{desc} </Text>
                              )
                            )}
                          </Text>
                        </View>

                        <View style={styles1.rRBProductImage}>
                          <Image
                            src={{
                              uri: item.image[0],
                              method: "GET",
                              headers: {
                                "Cache-Control": "no-cache",
                              },
                              body: "",
                            }}
                            style={styles1.rRBProductImageImg}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                </View>
              ) : (
                <View style={styles1.tableRow} wrap={true}>
                  <View style={styles1.rowLeft}>
                    <Text style={styles1.leftSl}>{getSlNo()}</Text>
                  </View>

                  <View style={styles1.rowRight}>
                    <View
                      style={[
                        styles1.rowRightAbove,
                        {
                          borderBottomWidth: 1,
                          borderColor: "#ADADAD",
                          borderBottomStyle: "solid",
                        },
                      ]}
                    >
                      <View style={styles1.rRAProductDetails}>
                        <Text
                          style={[
                            styles1.rRAProductDetailsText,
                            { fontWeight: "bold" },
                          ]}
                        >
                          {item.name}
                        </Text>

                        <Text
                          style={{
                            fontSize: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Specification:{" "}
                          <Text
                            style={{
                              fontSize: 10,
                              fontWeight: "normal",
                            }}
                          >
                            {(
                              item.size +
                              (item.warranty_duration !== "-"
                                ? " WITH " + item.warranty_duration
                                : "")
                            ).toUpperCase()}
                          </Text>
                        </Text>
                      </View>

                      <View style={styles1.rRABrand}>
                        <Text style={styles1.rRABrandText}>{item.brand}</Text>
                      </View>

                      <View style={styles1.rRAPPUEGst}>
                        <Text style={styles1.rRAPPUEGstText}>
                          {formatIndianNumber(parseFloat(item.spegst))}
                        </Text>
                      </View>

                      <View style={styles1.rRAGst}>
                        <Text style={styles1.rRAGstText}>{item.gst}</Text>
                      </View>

                      <View style={styles1.rRAPPUIGst}>
                        <Text style={styles1.rRAPPUIGstText}>
                          {formatIndianNumber(parseFloat(item.spigst))}
                        </Text>
                      </View>

                      <View style={styles1.rRAQuantity}>
                        <Text style={styles1.rRAQuantityText}>
                          {item.quantity}
                        </Text>
                      </View>

                      <View style={styles1.rRATotalAmount}>
                        <Text style={styles1.rRATotalAmountText}>
                          {formatIndianNumber(
                            parseFloat(item.spigst * item.quantity)
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              )}
            </Fragment>
          );
        })
      )}
    </View>
  );

  const TableTotal = () => (
    <View style={styles1.tableTotal} wrap={false}>
      <View style={styles1.tTRow}>
        <View style={styles1.tTLeft}>
          <Text style={styles1.tTLeftText}>Total Amount Excl. GST:</Text>
        </View>

        <View style={styles1.tTRight}>
          <Text style={styles1.tTRightText}>
            {formatIndianNumber(parseFloat(totalAmountEGst))}
          </Text>
        </View>
      </View>
      <View style={styles1.tTRow}>
        <View style={styles1.tTLeft}>
          <Text style={styles1.tTLeftText}>Total Amount Incl. GST:</Text>
        </View>

        <View style={styles1.tTRight}>
          <Text style={styles1.tTRightText}>
            {formatIndianNumber(parseFloat(totalAmount))}
          </Text>
        </View>
      </View>
      {/* {quotations[0].discountInPercent > 0 && (
        <View style={styles1.tTRow}>
          <View style={styles1.tTLeft}>
            <Text style={styles1.tTLeftText}>Discount in Percent (%):</Text>
          </View>

          <View style={styles1.tTRight}>
            <Text style={styles1.tTRightText}>
              {quotations[0].discountInPercent}%
            </Text>
          </View>
        </View>
      )} */}
      {quotations[0].discountInRupees > 0 && (
        <View style={styles1.tTRow}>
          <View style={styles1.tTLeft}>
            <Text style={styles1.tTLeftText}>Discount (Rs.):</Text>
          </View>

          <View style={styles1.tTRight}>
            <Text style={styles1.tTRightText}>
              {formatIndianNumber(parseFloat(quotations[0].discountInRupees))}
            </Text>
          </View>
        </View>
      )}
      <View style={styles1.tTRow}>
        <View style={styles1.tTLeft}>
          <Text style={styles1.tTLeftText}>Net Payable:</Text>
        </View>

        {quotations[0].discountInRupees > 0 ? (
          <View style={styles1.tTRight}>
            <Text style={styles1.tTRightText}>
              {formatIndianNumber(
                parseFloat(totalAmount - quotations[0].discountInRupees)
              )}
            </Text>
          </View>
        ) : (
          <View style={styles1.tTRight}>
            <Text style={styles1.tTRightText}>
              {formatIndianNumber(parseFloat(totalAmount))}
            </Text>
          </View>
        )}
      </View>
    </View>
  );

  const Annexure = () => (
    <Fragment>
      <View style={styles1.annHeadingView}>
        <Text style={styles1.annHeading}>ANNEXURE</Text>
      </View>
      <View style={styles1.ulView}>
        <Text style={styles1.ulLi}>
          • This document supersedes all other documents previously exchanged
          between the parties.
        </Text>
        <Text style={styles1.ulLi}>
          • The order will be placed under the official name of Savdo
          Technologies Pvt Ltd.
        </Text>
        <Text style={styles1.ulLi}>
          • The validity of the quotation is contingent upon the availability of
          stocks at the time of confirmation.
        </Text>
        <Text style={styles1.ulLi}>
          • Please note that the prices quoted in this document do not include
          additional charges such as Insurance, Packaging, and Forwarding (P&F)
          Charges. These charges may vary depending on the specific product and
          shipping address.
        </Text>
        <Text style={styles1.ulLi}>
          • Any applicable taxes, duties, delivery charges, levies,
          transportation fees, or similar charges will be considered extra and
          will be the sole responsibility of the purchaser.
        </Text>
        <Text style={styles1.ulLi}>
          • This quotation is valid for a period of 3 days from the date it was
          issued. After this period, the prices and availability of the products
          may be subject to change.
        </Text>
        <Text style={styles1.ulLi}>
          • As per our Return Policy, once the goods are sold, they cannot be
          returned unless otherwise stated or specified.
        </Text>
        <Text style={styles1.ulLi}>
          • Post-warranty service for medical equipment or machinery traded
          under this invoice will be provided at the sole discretion of Savdo
          Technologies Pvt Ltd or its authorised affiliates and will incur
          separate costs.
        </Text>
        <Text style={styles1.ulLi}>
          • In the event of any disputes arising from this quotation, all
          matters will be resolved through sole arbitration conducted by Savdo
          Technologies Pvt Ltd.
        </Text>
        <Text style={styles1.ulLi}>
          • Please be aware that the terms and conditions outlined in this
          document are subject to the jurisdiction and laws of Gurugram,
          ensuring adherence to the legal framework governing the region.
        </Text>
        <Text style={styles1.ulLi}>
          • We hereby confirm that we have obtained and maintained all the
          necessary licence(s) and registration(s) as required by the applicable
          laws, which are currently valid and in force as of the present date.
        </Text>
      </View>
      <View>
        <Text style={styles1.note}>
          <Text style={styles1.noteSpan}>NOTE: </Text>You can make any
          applicable advance payment to confirm the order
        </Text>
      </View>
      <View style={styles1.paymentDetails}>
        <View style={styles1.paymentDetailsLeft}>
          <Text style={styles1.accDetails}>Account Details</Text>
          <Text style={styles1.accInfo}>
            <Text style={styles1.accInfoSpan}>Name:</Text> Savdo Technologies
            Pvt. Ltd.
          </Text>
          <Text style={styles1.accInfo}>
            <Text style={styles1.accInfoSpan}>Account Number:</Text>{" "}
            50200069329058
          </Text>
          <Text style={styles1.accInfo}>
            <Text style={styles1.accInfoSpan}>IFS Code: </Text> HDFC0003654
          </Text>
        </View>
        <View style={styles1.paymentDetailsMiddle}>
          <Text style={styles1.orTxt}>OR</Text>
        </View>

        <View style={styles1.paymentDetailsRight}>
          <Image style={styles1.paymentQr} src={qr}></Image>
          <Text style={styles1.scanQr}>Scan QR Code</Text>
        </View>
      </View>

      <View style={styles1.annFooter}>
        <Text style={styles1.annFooterText}>
          For any assistance, do not hesitate to reach out to our customer
          support team at support@zoplar.com or call us at (+91) 8000 8111 60.
        </Text>
        <Text style={styles1.annFooterThank}>
          Thank you for considering ZOPLAR!
        </Text>
      </View>
    </Fragment>
  );

  const Footer = () => (
    <View style={styles1.footerMain} fixed>
      <Text style={styles1.footerLink}>www.zoplar.com</Text>
      <Text
        style={styles1.footerPage}
        render={({ pageNumber, totalPages }) => {
          return `${pageNumber} of ${totalPages}`;
        }}
      ></Text>
    </View>
  );

  const WaterMark = () => (
    // <Text style={styles1.watermark} fixed>
    // ZOPLAR
    // </Text>
    // <Image
    //   style={styles1.watermark}
    //   src={"https://i.ibb.co/Q9ChM1T/QUOTATION-NOT-VALID-PREVIEW-ONLY-2.png"}
    //   fixed
    // />
    <Image style={styles1.watermark} src={logo1} fixed />
  );
  return (
    <Document>
      <Page size="A4" style={styles1.page}>
        <WaterMark />
        <InvoiceTitle />
        <PreviewTitle />
        <Address />
        <UserAddress />
        <ItemsTable />
        <TableTotal quotations={quotations} />
        {quotations[0].isPaymentTable && <PaymentTable />}
        {quotations[0].attachmentDetails.length > 0 && <AttachementLinks />}
        <Footer />
      </Page>

      {/* page style should be avoided for annexure page */}
      <Page size="A4">
        <WaterMark />
        <InvoiceTitle />
        <PreviewTitle />
        <Annexure />
        <Footer />
      </Page>
    </Document>
  );
});

export default Frameheader;
